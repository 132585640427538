import React, { Fragment } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";

import { theme } from "../common/theme";
import { ResetCSS } from "../assets/css/style";
import { ContentWrapper, GlobalStyle } from "../containers/saas.style";
import { Footer, Navbar } from "@containers/common";
import { DrawerProvider } from "../contexts/DrawerContext";
import { SEO } from "@components/index";
import { PrivacyPolicy } from "@containers/common";

const browser = typeof window !== "undefined" && window;

const PrivacyPolicyPage = () => {
    return browser && (
        <ThemeProvider theme={theme}>
            <Fragment>
                <SEO title="Privacy Policy" url="/privacy-policy" />
                <ResetCSS />
                <GlobalStyle />
                <ContentWrapper>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                        <DrawerProvider>
                            <Navbar notMainPage />
                        </DrawerProvider>
                    </Sticky>
                    <PrivacyPolicy />
                    <Footer notMainPage />
                </ContentWrapper>
            </Fragment>
        </ThemeProvider>
    );
};

export default PrivacyPolicyPage;
